
const config = {
    baseURL: window.location.origin,
    // imageOSS:"https://oss.qianniangy.net",
    // baseURL: "https://zhfwtest.daoben.tech",
}

if (process.env.NODE_ENV === 'development') {
    config.baseURL = "http://kamenevents.cn/"
    // config.baseURL = "http://kamenerqi.daoben.tech/"
    
}
export default config
